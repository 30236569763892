/* eslint-disable no-console, max-lines*/
/* global google */

export class DealerLocatorMap {
  /**
   * Checks if the dealer locator map is on the page.
   * @returns {boolean} True if the dealer locator is on the page, false otherwise.
   */
  static isOnPage() {
    return $('.dealer-locator').length > 0;
  }

  /**
   * Initializes the DealerLocatorMap instance.
   */
  constructor() {
    this.map = null;
    this.infoWindow = null;
    this.selectedCity = null;
    this.selectedLocation = null;
    this.locations = [];
    this.markerLocationPairs = [];
    this.attachEvents();
  }

  /**
   * Initializes the Google Map and sets up required configurations.
   */
  initMap() {
    this.getCountryCenter().then((defaultCenter) => {
      this.map = new google.maps.Map($('.dealer-locator-map')[0], {
        center: defaultCenter.center,
        zoom: defaultCenter.zoom,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      });

      this.infoWindow = new google.maps.InfoWindow();

      $.ajax({
        url: '/DealerLocator/Dealers',
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })
        .done((data) => {
          this.locations = data;
          this.createMarkers(false);
        });

      const input = document.querySelector('input[type="text"]');
      const searchBox = new google.maps.places.SearchBox(input);

      searchBox.setBounds({
        north: 46.0,
        south: 42.0,
        west: 18.0,
        east: 23.0,
      });

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) {
          return;
        }

        const place = places[0];
        if (!place.geometry) {
          return;
        }

        let cityName = '';
        place.address_components.forEach((component) => {
          if (component.types.includes('locality')) {
            cityName = component.long_name;
          }
        });

        if (!cityName) {
          return;
        }

        this.selectedCity = cityName;
        this.selectedLocation = place.geometry.location;
      });
    });
  }

  /**
   * Clears all map markers.
   */
  clearMarkers() {
    this.markerLocationPairs.forEach(({ marker }) => {
      marker.setMap(null);
    });
  }

  /**
   * Retrieves the default map center based on the country.
   * @returns {Promise<Object>} A promise resolving to the center coordinates and zoom level.
   */
  getCountryCenter() {
    return new Promise((resolve) => {
      const domain = window.location.hostname.split('.').pop();

      const domainToCountryName = {
        'rs': 'Serbia',
        'de': 'Germany',
        'fr': 'France',
        'us': 'United States',
        'ca': 'Canada',
        'uk': 'United Kingdom',
        'au': 'Australia',
        'in': 'India',
        'cn': 'China',
        'jp': 'Japan',
        'br': 'Brazil',
        'ru': 'Russia',
        'za': 'South Africa',
        'mx': 'Mexico',
        'ar': 'Argentina',
        'it': 'Italy',
        'es': 'Spain',
        'pt': 'Portugal',
        'nl': 'Netherlands',
        'se': 'Sweden',
        'no': 'Norway',
        'dk': 'Denmark',
        'fi': 'Finland',
        'pl': 'Poland',
        'ch': 'Switzerland',
        'at': 'Austria',
        'be': 'Belgium',
        'ie': 'Ireland',
        'gr': 'Greece',
        'tr': 'Turkey',
        'cz': 'Czech Republic',
        'hu': 'Hungary',
        'sk': 'Slovakia',
        'bg': 'Bulgaria',
        'ro': 'Romania',
        'si': 'Slovenia',
        'hr': 'Croatia',
        'ba': 'Bosnia and Herzegovina',
        'me': 'Montenegro',
        'mk': 'North Macedonia',
        'al': 'Albania',
        'mt': 'Malta',
        'cy': 'Cyprus',
        'ee': 'Estonia',
        'lt': 'Lithuania',
        'lv': 'Latvia',
        'is': 'Iceland',
        'ua': 'Ukraine',
        'by': 'Belarus',
        'ge': 'Georgia',
        'am': 'Armenia',
        'az': 'Azerbaijan',
        'kz': 'Kazakhstan',
        'uz': 'Uzbekistan',
        'kg': 'Kyrgyzstan',
        'tj': 'Tajikistan',
        'tm': 'Turkmenistan',
        'af': 'Afghanistan',
        'pk': 'Pakistan',
        'bd': 'Bangladesh',
        'lk': 'Sri Lanka',
        'np': 'Nepal',
        'mv': 'Maldives',
        'id': 'Indonesia',
        'ph': 'Philippines',
        'my': 'Malaysia',
        'sg': 'Singapore',
        'th': 'Thailand',
        'vn': 'Vietnam',
        'kh': 'Cambodia',
        'la': 'Laos',
        'mm': 'Myanmar',
        'kr': 'South Korea',
        'kp': 'North Korea',
        'mn': 'Mongolia',
        'sa': 'Saudi Arabia',
        'ae': 'United Arab Emirates',
        'qa': 'Qatar',
        'kw': 'Kuwait',
        'om': 'Oman',
        'bh': 'Bahrain',
        'iq': 'Iraq',
        'ir': 'Iran',
        'il': 'Israel',
        'jo': 'Jordan',
        'lb': 'Lebanon',
        'sy': 'Syria',
        'eg': 'Egypt',
        'ma': 'Morocco',
        'tn': 'Tunisia',
        'dz': 'Algeria',
        'ly': 'Libya',
        'sd': 'Sudan',
        'ss': 'South Sudan',
        'et': 'Ethiopia',
        'ke': 'Kenya',
        'ug': 'Uganda',
        'tz': 'Tanzania',
        'rw': 'Rwanda',
        'bi': 'Burundi',
        'cd': 'Democratic Republic of the Congo',
        'cg': 'Republic of the Congo',
        'ao': 'Angola',
        'zm': 'Zambia',
        'mw': 'Malawi',
        'zw': 'Zimbabwe',
        'bw': 'Botswana',
        'na': 'Namibia',
        'ls': 'Lesotho',
        'mg': 'Madagascar',
        'mu': 'Mauritius',
        'sc': 'Seychelles',
        'cm': 'Cameroon',
        'gh': 'Ghana',
        'ng': 'Nigeria',
        'sn': 'Senegal',
        'ci': 'Ivory Coast',
        'ml': 'Mali',
        'bf': 'Burkina Faso',
        'ne': 'Niger',
        'td': 'Chad',
        'mr': 'Mauritania',
        'gm': 'Gambia',
        'sl': 'Sierra Leone',
        'lr': 'Liberia',
        'gu': 'Guinea',
        'gn': 'Guinea-Bissau',
        'cv': 'Cape Verde',
        'bj': 'Benin',
        'tg': 'Togo',
        'ga': 'Gabon',
        'gq': 'Equatorial Guinea',
        'st': 'São Tomé and Príncipe',
        'sz': 'Swaziland',
        'fj': 'Fiji',
        'pg': 'Papua New Guinea',
        'ws': 'Samoa',
        'to': 'Tonga',
        'vu': 'Vanuatu',
        'sb': 'Solomon Islands',
        'nc': 'New Caledonia',
        'nz': 'New Zealand',
        'pf': 'French Polynesia',
        'ck': 'Cook Islands',
        'tk': 'Tokelau',
        'tv': 'Tuvalu',
        'ki': 'Kiribati',
        'nr': 'Nauru',
        'mh': 'Marshall Islands',
        'pw': 'Palau',
        'as': 'American Samoa',
        'mp': 'Northern Mariana Islands',
        'um': 'United States Minor Outlying Islands',
        'fk': 'Falkland Islands',
        'ai': 'Anguilla',
        'bm': 'Bermuda',
        'vg': 'British Virgin Islands',
        'ky': 'Cayman Islands',
        'tc': 'Turks and Caicos Islands',
        'ms': 'Montserrat',
        'bb': 'Barbados',
        'ag': 'Antigua and Barbuda',
        'dm': 'Dominica',
        'lc': 'Saint Lucia',
        'vc': 'Saint Vincent and the Grenadines',
        'kn': 'Saint Kitts and Nevis',
        'gd': 'Grenada',
        'jm': 'Jamaica',
        'tt': 'Trinidad and Tobago',
        'bz': 'Belize',
        'cr': 'Costa Rica',
        'pa': 'Panama',
        'gt': 'Guatemala',
        'hn': 'Honduras',
        'ni': 'Nicaragua',
        'sv': 'El Salvador',
        'do': 'Dominican Republic',
        'ht': 'Haiti',
        'cu': 'Cuba',
        'pr': 'Puerto Rico'
      };

      const countryName = domainToCountryName[domain] || 'World';

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: countryName }, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve({
            center: results[0].geometry.location,
            zoom: 5
          });
        } else {
          resolve({
            center: { lat: 0.0, lng: 0.0 },
            zoom: 2
          });
        }
      });
    });
  }

  /**
   * Creates markers for all locations on the map.
   * @param {boolean} showMarkers - Whether to display the markers on the map.
   */
  createMarkers(showMarkers) {
    const geocoder = new google.maps.Geocoder();
    const promises = [];
    const customIcon = {
      url: '/assets/img/jobst/jobst-logo.png',
      scaledSize: new google.maps.Size(70, 25)
    };

    this.locations.forEach((location) => {
      let latLngPromise;

      if (location.Latitude && location.Longitude) {
        const lat = parseFloat(location.Latitude);
        const lng = parseFloat(location.Longitude);
        const latLng = new google.maps.LatLng(lat, lng);
        latLngPromise = Promise.resolve(latLng);
      } else {
        const address = `${location.Street}, ${location.Location}, ${location.Zip}`;
        latLngPromise = new Promise((resolve) => {
          geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK' && results[0]) {
              resolve(results[0].geometry.location);
            } else {
              resolve(null);
            }
          });
        });
      }

      promises.push(
        latLngPromise.then((latLng) => {
          if (latLng) {
            const marker = new google.maps.Marker({
              position: latLng,
              map: showMarkers ? this.map : null,
              title: location.Name,
              icon: customIcon
            });

            marker.addListener('click', () => {
              this.showInfoWindow(marker, location);
            });

            this.markerLocationPairs.push({ marker, location });
          }
        })
      );
    });

    Promise.all(promises);
  }

  /**
   * Displays an info window for a specific marker.
   * @param {google.maps.Marker} marker - The marker to associate with the info window.
   * @param {Object} location - The location details for the marker.
   */
  showInfoWindow(marker, location) {
    const address = `${location.Street}, ${location.Location}, ${location.Zip}`;
    const contentString = `
      <div class="google-maps-marker-container">
        <p><strong>${location.Name}</strong></p>
        ${address}<br />
        <a href="tel:${location.Phone}">${location.Phone}</a><br />
      </div>
    `;

    this.infoWindow.setContent(contentString);
    this.infoWindow.open(this.map, marker);
  }

  /**
   * Calculates the zoom level based on the distance.
   * @param {number} distance - The distance in kilometers.
   * @returns {number} The zoom level for the given distance.
   */
  getZoomLevelForDistance(distance) {
    const zoomLevels = [
      { maxDistance: 5, zoom: 13 },
      { maxDistance: 10, zoom: 12 },
      { maxDistance: 20, zoom: 11 },
      { maxDistance: 30, zoom: 10 },
      { maxDistance: 50, zoom: 9 },
      { maxDistance: 100, zoom: 8 },
      { maxDistance: 500, zoom: 7 },
      { maxDistance: Infinity, zoom: 6 },
    ];

    const zoomLevel = zoomLevels.find((level) => distance <= level.maxDistance);

    return zoomLevel ? zoomLevel.zoom : 6;
  }

  /**
   * Filters markers by distance from a given center.
   * @param {google.maps.LatLng} center - The center point.
   * @param {number} distance - The distance in kilometers.
   * @returns {Array<Object>} Filtered markers and their associated locations.
   */
  filterMarkersByDistance(center, distance) {
    if (!google.maps.geometry || !google.maps.geometry.spherical) {
      return;
    }

    const filteredMarkers = [];
    this.markerLocationPairs.forEach(({ marker, location }) => {
      const markerLatLng = marker.getPosition();
      const distanceFromCenter =
        google.maps.geometry.spherical.computeDistanceBetween(
          center,
          markerLatLng
        ) / 1000;
      if (distanceFromCenter <= distance) {
        marker.setMap(this.map);
        filteredMarkers.push({ marker, location });
      } else {
        marker.setMap(null);
      }
    });

    $('.num-of-dealers').text(filteredMarkers.length);

    return filteredMarkers;
  }

  /**
   * Displays search results based on the selected city and filters.
   */
  displaySearchResults() {
    if (!this.selectedCity || !this.selectedLocation) {
      return;
    }

    const distance = $('.dealer-locator-distance').val();
    const filterType = $('.dealer-locator-filter').val() || 'default';
    const zoomLevel = this.getZoomLevelForDistance(distance);
    const filteredMarkersByDistance = this.filterMarkersByDistance(this.selectedLocation, distance);

    this.clearMarkers();

    const filteredMarkers = filteredMarkersByDistance.filter(({ location }) => {
      return filterType === 'default' || (location[filterType] !== undefined && location[filterType] === true);
    });

    filteredMarkers.forEach(({ marker }) => {
      marker.setMap(this.map);
    });

    $('.num-of-dealers').text(filteredMarkers.length);

    this.map.setCenter(this.selectedLocation);
    this.map.setZoom(zoomLevel);

    this.clearMarkers();
    filteredMarkers.forEach(({ marker }) => {
      marker.setMap(this.map);
    });

    if (filteredMarkers.length > 0) {
      const isDesktop = window.innerWidth > 992;

      if (isDesktop) {
        $('.dealer-locator-options').fadeOut(() => {
          this.showSearchResults(filteredMarkers);
        });
      } else {
        this.showSearchResults(filteredMarkers);
      }
    }
  }

  /**
   * Renders the search results in the results section.
   * @param {Array<Object>} filteredMarkers - The markers to display in the results.
   */
  showSearchResults(filteredMarkers) {
    const resultsList = $('.dealer-locator-search-results-list');
    resultsList.empty();

    filteredMarkers.forEach(({ location }, index) => {
      const address = `${location.Street}, ${location.Location}, ${location.Zip}`;
      resultsList.append(`
        <li>
          <strong>${location.Name}</strong><br />
          ${address}<br />
          <a href="tel:${location.Phone}">${location.Phone}</a><br />
          <button class="dealer-locator-view-marker-btn" data-marker-index="${index}">></button>
        </li>
      `);
    });

    $('.dealer-locator-view-marker-btn').on('click', (e) => {
      const markerIndex = $(e.target).data('marker-index');
      const { marker, location } = filteredMarkers[markerIndex];

      if (window.innerWidth < 992) {
        $('.dealer-locator-show-map').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-results').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeOut();
      }

      this.map.setCenter(marker.getPosition());
      this.map.setZoom(14);
      this.showInfoWindow(marker, location);
    });

    if (window.innerWidth < 992) {
      $('.dealer-locator-mobile-result-selector').fadeIn();
    }

    $('.dealer-locator-search-results').fadeIn();
  }

  /**
   * Attaches event listeners for the dealer locator map functionality.
   */
  attachEvents() {
    const that = this;
    const isDesktop = window.innerWidth > 992;

    if (!isDesktop) {
      $('.dealer-locator-show-map').on('click', () => {
        $('.dealer-locator-show-map').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-results').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeOut();
      });

      $('.dealer-locator-show-results').on('click', () => {
        $('.dealer-locator-show-results').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-map').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeIn();
      });
    }

    $(document).on('click', '.dealer-locator-search', function () {
      that.displaySearchResults();
    });

    $('.dealer-locator-back-btn').on('click', () => {
      if (isDesktop) {
        $('.dealer-locator-search-results').fadeOut(() => {
          $('.dealer-locator-options').fadeIn();
        });
      } else {
        $('.dealer-locator-search-results').fadeOut();
      }
    });
  }
}

/**
 * Initializes the Dealer Locator Map functionality if it is on the current page.
 * This script dynamically loads the Google Maps API and sets a callback function to initialize the map.
 */
$(document).ready(() => {
  if (!DealerLocatorMap.isOnPage()) {
    return;
  }

  function initMap() {
    const mapInstance = new DealerLocatorMap();
    mapInstance.initMap();
  }

  window.dlrLocatorInitMap = initMap;

  const script = document.createElement('script');
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBZbiCL_JUyEbii0JmuYJ1sSme_zsTgpcY&libraries=places,geometry&callback=dlrLocatorInitMap&loading=async';

  document.body.appendChild(script);
});